.#{$prefix}link {
	cursor: pointer;
	text-decoration: none;
	color: hsl(var(--hsl-primary));

	&:hover {
		color: hsl(var(--hsl-primary-active));
	}

	&.is-unset-color {
		color: unset;

		&:hover {
			color: hsl(var(--hsl-primary-active));
		}
	}

	&.is-variant-underline {
		text-decoration: underline;
	}

	&.is-variant-dot {
		border-bottom: 1px dotted hsl(var(--hsl-primary));

		&:hover {
			text-decoration: none;
		}
	}
}
