@import '../../../styles/variable';
@import '../../../styles/ultis.scss';

.ant-tooltip-inner {
  user-select: none;
}

.modal-select-tokenn {
  max-width: 420px;
  max-height: 596px;

  .ant-modal-content {
    .ant-modal-header {
      padding: 52px 40px 21px 40px;
    }

    .ant-modal-title {
      font: normal 700 25px/35px var(--ffml-primary);
      @include font-size(lg);
    }

    .ant-modal-body {
      padding: 0px 0px 10px;

      .search {
        display: flex;
        align-items: center;
        height: 45px;
        width: 358px;
        background-color: $color-background;
        margin: 0 auto;
        margin-bottom: 30px;

        .search__img {
          width: to-rem(20px);
          margin: 0px 10px;
          &--error {
            height: to-rem(20px);
            background: $color-text;
          }
        }

        input {
          border: none;
          outline: none;
          background-color: $color-background;
          font-family: 'Lato', sans-serif;
          width: 270px;
          @include font-size(md);
        }

        @include media-query-to-mobile(md) {
          width: 100%;
          margin-bottom: to-rem(10px);

          input {
            width: 75%;
          }
        }
      }

      .list-token {
        height: 420px;
        list-style: none;
        margin: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 6px;
          background-color: $color-card;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          height: 10px;
          background-color: rgba(79, 79, 79, 0.2);
        }
        &__item {
          //  display: flex;
          cursor: pointer;
          user-select: none;
          .ant-col {
            display: flex;
            align-items: center;
            // word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .image {
            min-width: 25px;
            height: 25px;
            &--error {
              padding: 12.5px;
              background: $color-green-yellow;
            }
          }
          .item__checked {
            justify-content: flex-end;
            .image {
              width: 20px;
              &--error {
                height: 20px;
                background: $color-green-yellow;
              }
            }
          }
          .label {
            &__text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }
          .detail-token {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: to-rem(10px);
          }
          &:hover {
            background-color: $color-blue;
          }
          @include media-query-to-mobile(md) {
            padding: 10px 5px 10px 0px;
          }
        }
      }

      .spin-cfoundontainer {
        height: to-rem(420px);
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: to-rem(60px) 0;
        .spinning {
          width: to-rem(107px);
          height: to-rem(120px);
          margin-bottom: to-rem(30px);
        }
      }
    }
  }
}
