.#{$prefix}radio {
	display: flex;
	align-items: center;
	gap: toEm(8);

	input {
		flex-shrink: 0;
		width: toEm(22);
		height: toEm(22);
		cursor: pointer;
		transition: all 0.16s ease-in-out;
		border-width: toEm(2);
		border-style: solid;
		border-color: hsl(var(--hsl-content) / 0.3);
		border-radius: 100%;
		background: transparent;
		-webkit-appearance: none;
		appearance: none;

		&:checked {
			animation: radio-animate 0.16s cubic-bezier(0.42, 0, 0.23, 1.89);
			border-style: solid;
			border-color: hsl(var(--hsl-primary));
			background: hsl(var(--hsl-primary));
			box-shadow: 0 0 0 toEm(4) hsl(var(--hsl-base-100)) inset, 0 0 0 toEm(4) hsl(var(--hsl-base-100)) inset;

			&[disabled] {
				opacity: 0.7;
				background: hsl(var(--hsl-primary));
			}
		}

		&[disabled],
		&[disabled] + label {
			cursor: not-allowed;
			color: hsl(var(--hsl-content) / 0.5);
		}

		&[disabled] {
			opacity: 0.7;
			background-color: hsl(var(--hsl-content) / 0.1);
		}
	}

	label {
		font-size: toRem(15);
		font-weight: 500;
		cursor: pointer;
	}

	@keyframes radio-animate {
		0% {
			box-shadow: 0 0 0 0 hsl(var(--hsl-base-100)) inset, 0 0 0 0 hsl(var(--hsl-base-100)) inset;
		}
		100% {
			box-shadow: 0 0 0 toEm(4) hsl(var(--hsl-base-100)) inset, 0 0 0 toEm(4) hsl(var(--hsl-base-100)) inset;
		}
	}
}
