.#{$prefix}textarea {
	font-size: var(--form-element-medium-font-size);
	font-weight: 400;
	position: relative;
	display: block;
	flex-direction: column;
	border-radius: var(--form-element-border-radius);
	gap: toEm(6);

	textarea {
		@extend %base-input;

		padding: toEm(12) toEm(12);
	}

	textarea[readonly] {
		background: hsl(var(--hsl-content) / 0.05);
	}

	textarea[disabled] {
		cursor: not-allowed;
		background: hsl(var(--hsl-content) / 0.05);
	}
}
