.#{$prefix}card {
	padding: toEm(16) toEm(24);
	border: 1px solid hsl(var(--hsl-primary));
	border-radius: toEm(16);
	background-color: hsl(var(--hsl-content) / 0.05);

	&.is-raised-100 {
		box-shadow: var(--bsd-100);
	}

	&.is-raised-200 {
		box-shadow: var(--bsd-200);
	}

	&.is-raised-300 {
		box-shadow: var(--bsd-300);
	}

	&.is-raised-400 {
		box-shadow: var(--bsd-400);
	}
}
