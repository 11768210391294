@import './styles/_variable';
@import './styles/ultis.scss';
@import 'antd/dist/antd.css';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #383a52 inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

body {
  width: 100% !important;
}

#root {
  min-height: 100%;
  background: black url('/assets/images/gradient-bg.svg') center/100% 100% no-repeat fixed;

  @include media-query-to-mobile(md) {

  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba($color-blue, 0.5);
  border-radius: 10px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
}

.ant-form-item-explain > div {
  min-height: 10px;
  margin: to-rem(7px) 0;
  font-size: to-rem(15px);
  color: $color-error-warning !important;
}

.ant-form-item-explain-error > div {
  margin: to-rem(7px) 0;
  min-height: 10px;
  font-size: to-rem(15px);
  color: $color-error-warning !important;
}

.ant-input {
  border-color: #333333;
}
.ant-btn:hover {
  background: transparent;
}

body {
  font-family: var(--ffml-primary);
  background-color: transparent;
}

.font-weight-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-color-text {
  color: $color-text;
}

.text-color-title {
  color: hsl(var(--hsl-content));
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.user-select-none {
  user-select: none;
}

.container {
  @include make-container();
}

$box-sizings: (
  none: 0px,
  3xsm: 2px,
  2xsm: 4px,
  xsm: 8px,
  sm: 12px,
  md: 16px,
  lg: 20px,
  xl: 30px,
  2xl: 48px,
  3xl: 60px,
  4xl: 64px,
  5xl: 96px,
  6xl: 128px,
  7xl: 160px,
);

@each $breakpoint, $value in $box-sizings {
  .m-#{$breakpoint} {
    margin: to-rem($value);
  }
  .mt-#{$breakpoint} {
    margin-top: to-rem($value);
  }
  .mr-#{$breakpoint} {
    margin-right: to-rem($value);
  }
  .mb-#{$breakpoint} {
    margin-bottom: to-rem($value);
  }
  .ml-#{$breakpoint} {
    margin-left: to-rem($value);
  }
  .mx-#{$breakpoint} {
    margin-left: to-rem($value);
    margin-right: to-rem($value);
  }
  .my-#{$breakpoint} {
    margin-top: to-rem($value);
    margin-bottom: to-rem($value);
  }
  .p-#{$breakpoint} {
    padding: to-rem($value);
  }
  .pt-#{$breakpoint} {
    padding-top: to-rem($value);
  }
  .pr-#{$breakpoint} {
    padding-right: to-rem($value);
  }
  .pb-#{$breakpoint} {
    padding-bottom: to-rem($value);
  }
  .pl-#{$breakpoint} {
    padding-left: to-rem($value);
  }
  .px-#{$breakpoint} {
    padding-left: to-rem($value);
    padding-right: to-rem($value);
  }
  .py-#{$breakpoint} {
    padding-top: to-rem($value);
    padding-bottom: to-rem($value);
  }
}

$border-radius-sizings: (
  none: 0px,
  sm: 3px,
  md: 5px,
  lg: 10px,
  xl: 24px,
  2xl: 30px,
  3xl: 50px,
);

@each $breakpoint, $value in $border-radius-sizings {
  .rounded-#{$breakpoint} {
    border-radius: to-rem($value);
  }
}

@each $breakpoint, $value in font-sizes {
  .text-#{$breakpoint} {
    font-size: $value;
  }
}

.ant-message {
  font-size: to-rem(18px);
  line-height: to-rem(28px);
  font-family: var(--ffml-primary);
  font-weight: 500;
  .ant-message-notice-content {
    width: to-rem(332px);
    border-radius: to-rem(8px);
    padding: to-rem(32px) !important;

    .ant-message-success {
      span {
        color: $color-green-yellow;
      }
      svg {
        path {
          color: $color-green-yellow;
        }
      }
    }
  }
}
.default-image {
  display: flex;
  background: $color-green-yellow;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  .default-image__text {
    font-size: 10px;
    font-weight: 700;
  }
}

.image-icon {
  width: to-rem(30px);
  max-height: to-rem(30px);
}

.cursor-pointer {
  cursor: pointer;
}

.message-icon {
  width: to-rem(20px);
  margin-right: to-rem(18px);
}

input#email.ant-input {
  background: #eeeeee !important;
}

.uppercase {
  text-transform: uppercase;
}

.headline1 {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 700;
  @include font-size(3xl);
  line-height: to-rem(55px);
}
.headline2 {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 600;
  @include font-size(2xl);
  line-height: to-rem(40px);
}
.headline3 {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 600;
  @include font-size(xl);
  line-height: to-rem(32px);
}
.headline4 {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 400;
  @include font-size(lg);
  line-height: to-rem(32px);
}
.headline6 {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 400;
  @include font-size(md);
  line-height: to-rem(20px);
}
.bodyLarge {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 400;
  @include font-size(sm);
  line-height: to-rem(20px);
}
.bodyLarge-sm {
  font-family: var(--ffml-primary);
  font-style: normal;
  font-weight: 400;
  @include font-size(2sm);
  line-height: to-rem(16px);
}

.ant-message {
  .ant-message-notice-content {
    background: $color-light-blue;
    * {
      color: $color-background;
    }
  }
}
