.#{$prefix}input {
	font-size: var(--form-element-medium-font-size);
	font-weight: 400;
	position: relative;
	display: block;
	flex-direction: column;
	height: var(--form-element-medium-height);
	border-radius: var(--form-element-border-radius);
	gap: toEm(6);

	input {
		@extend %base-input;

		padding: 0 toEm(12);
	}

	input[readonly] {
		background: hsl(var(--hsl-content) / 0.05);
	}

	input[disabled] {
		cursor: not-allowed;
		background: hsl(var(--hsl-content) / 0.05);
	}
}

.#{$prefix}input-group-vertical-pill {
	input {
		@extend %base-input;

		font-size: var(--form-element-medium-font-size);
		font-weight: 400;
		position: relative;
		display: block;
		flex-direction: column;
		width: 100%;
		height: var(--form-element-medium-height);
		padding: 0 0.75em;
		transition: all 0.16s ease-in-out;
		vertical-align: top;
		color: hsl(var(--hsl-content));
		border: 1px solid hsl(var(--hsl-content)/0.15);
		border-radius: var(--form-element-border-radius);
		outline: none;
		background-color: transparent;
		gap: 0.375em;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	input[readonly] {
		background: hsl(var(--hsl-content) / 0.05);
	}

	input[disabled] {
		cursor: not-allowed;
		background: hsl(var(--hsl-content) / 0.05);
	}

	input:first-child  {
		margin-bottom: -1px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	input:last-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
