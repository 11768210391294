.image {
  &--error {
    display: inline-block;
    border-radius: 50%;
    animation: liner-color 2s ease-in-out infinite;
  }
}
@keyframes liner-color {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.5;
  }
}
