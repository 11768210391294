@import '../../styles/ultis';
@import '../../styles/_variable';

.error-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    text-align: center;
    font-family: var(--ffml-primary);
    margin: to-rem(25px) 0;
    @include font-size(xl);
  }

  .redirect-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: to-rem(255px);
    height: to-rem(55px);
    font-weight: 600;
    color: #100f24;
    @include colorPalette;
    &:hover {
      animation: backgroundColorPalette 3.5s ease-in-out infinite alternate;
    }
    cursor: pointer;
  }
}
