.#{$prefix}menu {
	font-weight: 500;
	display: inline-flex;
	flex-direction: column;
	background: var(--menu-background, hsl(var(--hsl-base-100) / 1));

	&.is-card {
		border: 1px solid hsl(var(--hsl-content) / 0.15);
	}

	&.is-round-border {
		border-radius: var(--menu-border-radius, toEm(12));

		> *:first-child {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
		}

		> *:last-child {
			border-bottom-right-radius: inherit;
			border-bottom-left-radius: inherit;
		}

		&.is-variant-inset {
			> *:not(.group-label) {
				border-radius: toEm(8);
			}
		}
	}

	&.is-compact {
		> *:not(.group-label) > * {
			font-size: var(--menu-item-font-size, var(--fs-3));
			min-height: var(--menu-item-min-height, #{toEm(40)});
			padding: 0 toRem(16);
		}

		.group-label {
			font-size: var(--fs-2);
			padding: 0 toRem(16);
		}
	}

	&.is-variant-inset {
		padding: 12px;
	}

	.group-label {
		font-size: var(--fs-3);
		margin-bottom: toEm(8);
		padding: 0 toRem(24);
		color: hsl(var(--hsl-content) / 0.5);

		&:not(:first-child) {
			margin-top: toEm(24);
		}
	}

	&.is-active-variant-simple > *:not(.group-label) {
		&.is-active {
			color: hsl(var(--hsl-primary) / 1);
			background: transparent;

			> * {
				color: hsl(var(--hsl-primary) / 1);
			}

			&:hover {
				background: hsl(var(--hsl-content) / 0.05);
			}
		}

		&:hover {
			background: hsl(var(--hsl-content) / 0.05);
		}
	}

	> * {
		list-style: none;
	}

	> *:not(.group-label) {
		transition: all 0.16s ease-in-out;

		&.is-active {
			color: hsl(var(--hsl-primary-content) / 1);
			background: hsl(var(--hsl-primary) / 1);

			> * {
				color: hsl(var(--hsl-primary-content) / 1);
			}

			&:hover {
				background: hsl(var(--hsl-primary) / 1);
			}
		}

		&:hover {
			background: hsl(var(--hsl-content) / 0.05);
		}

		> * {
			font-size: var(--menu-item-font-size, var(--fs-4));
			display: flex;
			align-items: center;
			min-height: var(--menu-item-min-height, #{toEm(52)});
			padding: 0 toEm(24);
			cursor: pointer;
			white-space: nowrap;
		}
	}
}
