.#{$prefix}dropdown {
	position: relative;
	display: inline-block;
	--gap: #{toEm(4)};

	&:not(.is-hover-open) {
		&:focus-within {
			> *:nth-child(2) {
				transition: all 0.16s ease-in-out;
				transform: translate3d(0, 0, 0);
				pointer-events: all;
				opacity: 1;
			}
		}
	}

	&.is-position-bottom-right {
		:nth-child(2) {
			right: 0;
		}
	}

	&.is-hover-open {
		&:after {
			position: absolute;
			z-index: 0;
			bottom: calc(-1 * var(--gap));
			left: 0;
			width: 100%;
			height: var(--gap);
			content: ' ';
		}

		&:hover {
			> *:nth-child(2) {
				transition: all 0.16s ease-in-out;
				transform: translate3d(0, 0, 0);
				pointer-events: all;
				opacity: 1;
			}
		}

		:nth-child(2):hover {
			transition: all 0.16s ease-in-out;
			transform: translate3d(0, 0, 0);
			pointer-events: all;
			opacity: 1;
		}
	}

	> *:first-child {
		font-weight: 500;
		position: relative;
		z-index: 1;
		cursor: pointer;
	}

	> *:nth-child(2) {
		position: absolute;
		z-index: 2;
		top: calc(100% + var(--gap));
		left: 0;
		transform: translate3d(0, -8px, 0);
		pointer-events: none;
		opacity: 0;
	}
}
