@import '../../../styles/variable';
@import '../../../styles/ultis.scss';

.modal-select-network {
  max-width: 420px;

  .ant-modal-content {
    .ant-modal-header {
      padding: 30px 40px 21px 40px;
      padding-top: 52px;
      .ant-modal-title {
        font: normal 700 25px/35px var(--ffml-primary);
        @include font-size(lg);
      }
    }
    .ant-modal-body {
      padding: 0px 0px 10px;

      ul {
        height: auto;
        list-style: none;
        margin: 0;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 6px;
          background-color: $color-card;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          height: 10px;
          background-color: rgba(79, 79, 79, 0.2);
        }
        li {
          display: flex;
          height: 51px;
          padding-left: 30px;
          flex-flow: row nowrap;
          // align-items: center;
          cursor: pointer;
          .network-item {
            &__des {
              display: flex;
              align-items: center;
              .image {
                margin-right: 8px;
              }
              .net-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
          .item__checked {
            text-align: right;
          }
          .image {
            width: 25px;
            height: 25px;
            &--error {
              background: $color-green-yellow;
            }
          }
          span {
            color: hsl(var(--hsl-content)) !important;
            font-weight: 600;
          }
        }
        .li-hover:hover {
          background-color: hsl(var(--hsl-primary) / 0.3);
        }
        .li-disable {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
  .ant-modal-close {
    margin-top: to-rem(20px);
    margin-right: to-rem(20px);
  }

  .disable-select {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

@include media-query-to-mobile(md) {
  .modal-select-network {
    ul {
      li {
        padding: 0px !important;
      }
    }
  }
}
