@import '../../../styles/variable';
@import '../../../styles/ultis.scss';

.modal-rejected {
  max-width: 500px;
  &.loading,
  &.error,
  &.txSubmited {
    max-width: 383px;
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          @include font-size(lg);
          text-align: center;
        }
      }
    }
  }
  .ant-modal-content {
    .ant-modal-header {
      padding-top: 46px;
    }
    .ant-modal-body {
      padding: 0px 30px;
      h3 {
        padding: 0;
        text-align: left;
        margin-bottom: to-rem(15px);
        font-family: var(--ffml-primary);
        color: hsl(var(--hsl-content));
        @include media-query-to-mobile(md) {
          font-family: var(--ffml-primary);
        }
      }
      .nofitication {
        text-align: center;
        span {
          display: inline-block;
        }
        .nofitication__image {
          margin-bottom: 25px;
          &.loading {
            span {
              animation: loading 2s linear infinite;
              img {
                width: 67px;
                height: 67px;
              }
            }
          }
        }
        p {
          margin: 0;
          text-align: center;
          @include font-size(md);
          font-family: var(--ffml-primary);
          color: $color-text;
        }
      }
      .approve-loading {
        padding: 15px 0 30px 0;
      }
      .swap-loading {
        &__link {
          color: $color-primary;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .intro {
        $text-lineheight: 19px;
        display: flex;
        width: 440px;
        height: 169px;
        padding: 16px 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: $color-background;
        @include media-query-to-mobile(md) {
          flex-flow: column nowrap;
          width: 100%;
          height: 100%;
          align-items: center;
        }

        img {
          width: 30px;
          height: 30px;
        }
        p {
          display: inline-block;
          font: normal 400 16px/19px var(--ffml-primary);
          color: $color-text;
        }
        .show {
          display: flex;
          width: 160px;
          height: 101px;
          flex-flow: column nowrap;
          align-items: center;
          text-align: center;
          background-color: $color-secondary;
          border-radius: 5px;
          @include media-query-to-mobile(md) {
            width: 100%;
            height: to-rem(80px);
          }
          img {
            margin-top: 13px;
            margin-bottom: 10px;
          }
          .show__text {
            $max-lines: 2;
            display: -webkit-box;
            margin: 0 10px;
            max-height: $text-lineheight * $max-lines;
            overflow: hidden;
            -webkit-line-clamp: $max-lines;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            color: hsl(var(--hsl-content));
          }
        }

        .anticon {
          font-size: 20px;
          padding-top: 18%;
          margin: 0;
        }
      }

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        @include media-query-to-mobile(md) {
          align-items: flex-start;
        }
        .ant-checkbox {
          font-size: 0px;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $color-green-yellow;
          border-color: $color-green-yellow;
        }
        .checkbox-content {
          font-weight: 600;
          a {
            vertical-align: middle;
            color: $color-primary;
            &:hover {
              color: $color-blue;
            }
            @include media-query-to-mobile(md) {
              display: block;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0 30px;
      .ant-btn {
        width: 100%;
        height: 44px;
        background: $color-primary;
        border-radius: 5px;
        color: #fff;
        font-weight: 600;
        outline: none;
        border: none;
        margin: 30px auto;
        opacity: 0.8;
        & span {
          color: #100f24;
        }
        &.disable {
          opacity: 1;
          background: $color-btn-disable;
          span {
            color: $color-btn-text-disable;
          }
        }
        &.dismiss {
          background: $color-red;
          &:hover {
            opacity: 1;
          }
          span {
            color: hsl(var(--hsl-white));
          }
        }
        &.success {
          background: $color-primary;
          &:hover {
            opacity: 1;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &.error,
  &.txSubmited {
    max-width: 394px;
    .nofitication__image {
      margin-bottom: to-rem(20px) !important;
    }
    .ant-modal-header {
      padding-top: to-rem(42px);
      padding-bottom: to-rem(16px);
    }
  }
  &.txSubmited {
    .ant-modal-header {
      padding-top: to-rem(43px);
      padding-bottom: to-rem(30px);
    }
  }
}
@include media-query-to-mobile(md) {
  .modal-confirm {
    & .intro {
      .from,
      .to {
        width: 100% !important;
        margin-bottom: 5px;
      }
      & > span {
        transform: rotate(90deg);
        padding-top: 0 !important;
        padding-left: 15px;
      }
    }

    .ant-modal-close {
      margin-top: 20px !important;
    }
  }
}
