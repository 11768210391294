@import '../../styles/variable';
@import '../../styles/ultis.scss';

.ant-modal-mask {
  background-color: rgba(#000, 0.5);
}
.modal {
  .ant-modal-content {
    border-radius: 5px;
    background-color: hsl(var(--hsl-base-200));
  }
  .ant-modal-header {
    padding: 30px;
    border: none;
    border-radius: inherit;
    background-color: inherit;
    .ant-modal-title {
      color: hsl(var(--hsl-content));
      text-align: left;
      font: normal 600 22px/32px var(--ffml-primary);
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-close {
    margin-top: to-rem(30px);
    margin-right: to-rem(30px);
    &-x {
      width: unset;
      height: unset;
      line-height: unset;
    }
    // & > span {
    //   font-size: 20px;
    // }
    .btn-close {
      width: to-rem(16px);
      height: to-rem(16px);
      margin-top: 4px;
      &.btn-close--error {
        display: block;
        background: $color-text;
        border-radius: 50%;
      }
    }
  }
}

@include media-query-to-mobile(md) {
  .modal {
    width: 90% !important;
    padding: 0 !important;
    margin: 15px auto;
    .ant-modal-header {
      padding: 40px 20px 20px !important;
    }
    .ant-modal-body {
      padding: 0px to-rem(20px) !important;
    }
  }
}
