@import '../../../styles/variable';
@import '../../../styles/ultis.scss';

.modal-error {
  max-width: to-rem(394px);
  height: 374px;

  .ant-modal-content {
    .ant-modal-close {
      margin-top: to-rem(19px);
      margin-right: to-rem(30px);
      &-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
    .ant-modal-header {
      padding-top: 46px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-body {
      padding: 0px 30px 30px;
      margin-top: -15px;
      & > img {
        display: block;
        width: to-rem(80px);
        margin: 0 auto;
        padding-bottom: 16px;
      }
      & > h6 {
        display: inline-block;
        font: normal 400 16px/20px var(--ffml-primary);
        color: $color-text;
        text-align: center;
        margin: 0;
      }
    }

    .ant-modal-footer {
      padding: 6px 30px 30px 30px;
      .modal-error__close-btn {
        width: 100%;
        height: 44px;
        background: $color-btn-gradient;
        border-radius: 5px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
        font: normal 600 14px/20px var(--ffml-primary);
        outline: none;
        border: none;
        opacity: 0.9;
        &--content {
          color: #100f24;
        }
        &:hover {
          opacity: 1;
        }
      }

      .modal-error-dismiss__close-btn {
        width: 100%;
        height: 44px;
        background-color: $color-red;
        border-radius: 5px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
        font: normal 600 14px/20px var(--ffml-primary);
        outline: none;
        border: none;
        opacity: 0.9;
        color: #100f24;
        &--content {
          color: #100f24;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@include media-query-to-mobile(md) {
  .modal-error {
    .ant-modal-footer,
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      & > img {
        padding-top: to-rem(49px);
      }
      & > h6 {
        padding-bottom: to-rem(30px);
      }
    }
  }
}
