.#{$prefix}checkbox {
	display: flex;
	align-items: center;
	gap: toEm(8);

	&.is-indeterminate input,
	input:indeterminate {
		position: relative;
		animation: checkbox-int-animate 0.16s ease;
		border-color: hsl(var(--hsl-primary) / 1);
		background-color: hsl(var(--hsl-white));
		background-image: linear-gradient(90deg, transparent 80%, hsl(var(--hsl-primary)) 80%),
		linear-gradient(-90deg, transparent 80%, hsl(var(--hsl-primary)) 80%),
		linear-gradient(0deg, hsl(var(--hsl-primary)) 38%, transparent 38%, transparent 62%, hsl(var(--hsl-primary)) 62%);

		&[disabled] {
			opacity: 0.7;
			background-color: hsl(var(--hsl-white));
		}
	}

	input {
		flex-shrink: 0;
		width: toEm(22);
		height: toEm(22);
		cursor: pointer;
		border-width: toEm(2);
		border-style: solid;
		border-color: hsl(var(--hsl-content) / 0.3);
		border-radius: toRem(6);
		background: transparent;
		-webkit-appearance: none;
		appearance: none;

		&:checked {
			animation: checkbox-animate 0.16s ease;
			border-color: hsl(var(--hsl-primary) / 1);
			background-image: linear-gradient(-45deg, transparent 68%, hsl(var(--hsl-primary)) 68%),
			linear-gradient(45deg, transparent 78%, hsl(var(--hsl-primary)) 78%),
			linear-gradient(-45deg, hsl(var(--hsl-primary)) 40%, transparent 40%),
			linear-gradient(45deg,
					hsl(var(--hsl-primary)) 28%,
					hsl(var(--hsl-white)) 28%,
					hsl(var(--hsl-white)) 40%,
					transparent 40%
			),
			linear-gradient(-45deg, hsl(var(--hsl-white)) 52%,
					hsl(var(--hsl-primary)) 52%);

			&[disabled] {
				opacity: 0.7;
			}
		}

		&[disabled],
		&[disabled] + label {
			cursor: not-allowed;
			color: hsl(var(--hsl-content) / 0.5);
		}

		&[disabled] {
			opacity: 0.7;
			background-color: hsl(var(--hsl-content) / 0.1);
		}
	}

	label {
		font-size: toRem(15);
		font-weight: 500;
		cursor: pointer;
		user-select: none;
	}


	@keyframes checkbox-animate {
		0% {
			transform: scale(0.85);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes checkbox-int-animate {
		0% {
			transform: scale(0.85);
		}
		100% {
			transform: scale(1);
		}
	}
}
