@import '../../styles/variable';
@import '../../styles/ultis.scss';

// .drop-down {
.button {
  height: 36px;
  line-height: 28px;
  padding-left: 16px;
  background: #eff0f7;
  border: 1px solid #d9dbe9;
  border-radius: 6px;
  @include font-size(sm);
  &__arrowDown {
    display: flex;
    padding-left: 10px;
  }
}
// }
