.#{$prefix}table-container {
	position: relative;
	overflow-x: auto;
	overflow-y: visible;
	border-radius: var(--table-border-radius, toRem(4));

	&.is-scroll-padding {
		padding-right: #{toRem(16)};

		&:after {
			padding-right: #{toRem(16)};
		}
	}

	&:after {
		position: sticky;
		z-index: 1;
		right: 0;
		bottom: 1px;
		display: block;
		content: ' ';
		border-bottom: var(--table-border-width, toRem(1)) solid var(--table-border-color, hsl(var(--hsl-line) / 0.8));
	}
}

.#{$prefix}table {
	overflow-x: auto;
	overflow-y: visible;

	width: 100%;
	max-width: 100%;

	border-spacing: 0;
	border-collapse: separate;
	word-break: normal;
	border-bottom: toRem(1) solid var(--table-data-border-color, hsl(var(--hsl-line) / 0.7));

	thead {
		font-size: var(--table-head-font-size, toRem(15));
		overflow: hidden;
	}

	tbody {
		font-size: var(--table-data-font-size, toRem(14));
	}

	th, td {
		text-align: left;
		vertical-align: middle;

		&:first-child {
			padding-left: var(--table-side-padding, toEm(24));
		}

		&:not(:first-child) {
			padding-left: var(--table-gutter, toEm(24));
		}

		&:last-child {
			padding-right: var(--table-side-padding, toEm(24));
		}

		&.is-align-left {
			text-align: left;
		}

		&.is-align-center {
			text-align: center;
		}

		&.is-align-right {
			text-align: right;
		}
	}

	td {
		font-size: 14px;
		font-weight: 500;
		padding: var(--table-data-vertical-padding, toRem(16)) 0;
		white-space: nowrap;
		color: hsl(var(--hsl-primary));

		p {
			white-space: normal;
		}

		&.is-collapse {
			width: 1%;
		}
	}

	th {
		font-family: var(--table-head-font-family, var(--font-family-secondary));
		font-size: 12px;
		font-weight: var(--table-head-font-weight, 600);
		padding: var(--table-head-vertical-padding, #{toRem(12)}) 0;
		border-spacing: 5px 5px;

		border-collapse: separate;
		white-space: nowrap;
		color: hsl(var(--hsl-content) / 0.5);
		border-bottom: toRem(1) solid var(--table-data-border-color, hsl(var(--hsl-line) / 0.7));
		background: transparent;

		p {
			white-space: normal;
		}

		&.is-collapse {
			width: 1%;
		}
	}

	tr:not(:last-child) {
		td {
			border-bottom: toRem(1) solid var(--table-data-border-color, hsl(var(--hsl-line) / 0.7));
		}
	}

	&.is-variant-compact {
		tr td {
			padding-top: toRem(10);
			padding-bottom: toRem(10);
		}
	}

	&.is-header-sticky {
		tr th {
			position: sticky;
			z-index: 2;
			top: 0;
		}
	}
}
