.#{$prefix}panel {
	padding: toEm(16);
	border-radius: toEm(4);
	background: hsl(var(--hsl-base-100));

	&.is-level-100 {
		color: hsl(var(--hsl-content));
		background: hsl(var(--hsl-base-100));
	}

	&.is-level-200 {
		color: hsl(var(--hsl-content));
		background: hsl(var(--hsl-base-200));
	}

	&.is-level-300 {
		color: hsl(var(--hsl-content));
		background: hsl(var(--hsl-base-300));
	}

	&.is-level-400 {
		color: hsl(var(--hsl-content));
		background: hsl(var(--hsl-base-400));
	}
}
