[data-theme=evry-default] {
	--hue-primary: 338;
	--hsl-primary: var(--hue-primary) 79% 48%;
	--hsl-primary-active: var(--hue-primary) 79% 42%;
	--hsl-primary-content: var(--hue-primary) 0% 100%;

	--hue-accent: 12;
	--hsl-accent: var(--hue-accent) 60% 48%;
	--hsl-accent-active: var(--hue-accent) 60% 42%;
	--hsl-accent-content: var(--hue-accent) 0% 100%;

	--hue-base: 250;
	--hsl-base-100: var(--hue-base) 13% 24%;
	--hsl-base-200: var(--hue-base) 13% 20%;
	--hsl-base-300: var(--hue-base) 13% 16%;
	--hsl-base-400: 0, 0% 15%;

	--hsl-line: 222 20% 30%;

	--hsl-content: 0 0% 95%;
	--hsl-content-inv: 215 15% 12%;

	--hsl-positive: 168 72% 42%;
	--hsl-positive-content: 168 80% 95%;

	--hsl-negative: 5 78% 63%;
	--hsl-negative-content: 5 80% 97.5%;

	--hsl-warning: 45 90% 48%;
	--hsl-warning-content: 45 80% 97%;

	--hsl-info: 197 70% 54%;
	--hsl-info-content: 197 90% 97%;

	--hsl-black: 0 0% 0%;
	--hsl-white: 0 0% 100%;
	/*
	* Typography
	*********************************************/
	--ffml-primary: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
	--ffml-secondary: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
	--fs-14: 4.5rem;
	--fs-13: 3.5rem;
	--fs-12: 3rem;
	--fs-11: 2.5rem;
	--fs-10: 2.25rem;
	--fs-9: 2rem;
	--fs-8: 1.75rem;
	--fs-7: 1.5rem;
	--fs-6: 1.25rem;
	--fs-5: 1.125rem;
	--fs-4: 1rem;
	--fs-3: 0.9375rem;
	--fs-2: 0.875rem;
	--fs-1: 0.75rem;
	/*
	* Spacing
	*********************************************/
	--spc-0: 0rem;
	--spc-1: 0.0625rem;
	--spc-2: 0.125rem;
	--spc-3: 0.25rem;
	--spc-4: 0.5rem;
	--spc-5: 0.75rem;
	--spc-6: 1rem;
	--spc-7: 1.5rem;
	--spc-8: 2rem;
	--spc-9: 2.5rem;
	--spc-10: 3rem;
	--spc-11: 4rem;
	--spc-12: 6rem;
	--spc-13: 8rem;
	/*
	 * Box shadow
	 *********************************************/
	--bsd-hsl: 333deg 0% 50%;
	--bsd-100:
		0 0.0625rem 0.0625rem hsl(var(--bsd-hsl) / 0.05),
		0 0.125rem 0.125rem hsl(var(--bsd-hsl) / 0.05),
		0 0.25rem 0.25rem hsl(var(--bsd-hsl) / 0.05),
		0 0.5rem 0.5rem hsl(var(--bsd-hsl) / 0.05);
	--bsd-200:
		0 0.0625rem 0.0625rem hsl(var(--bsd-hsl) / 0.075),
		0 0.125rem 0.125rem hsl(var(--bsd-hsl) / 0.075),
		0 0.25rem 0.25rem hsl(var(--bsd-hsl) / 0.075),
		0 0.5rem 0.5rem hsl(var(--bsd-hsl) / 0.075),
		0 1rem 1rem hsl(var(--bsd-hsl) / 0.075);
	--bsd-300:
		0 0.0625rem 0.0625rem hsl(var(--bsd-hsl) / 0.1),
		0 0.125rem 0.125rem hsl(var(--bsd-hsl) / 0.1),
		0 0.25rem 0.25rem hsl(var(--bsd-hsl) / 0.1),
		0 0.5rem 0.5rem hsl(var(--bsd-hsl) / 0.1),
		0 1rem 1rem hsl(var(--bsd-hsl) / 0.1),
		0 2rem 2rem hsl(var(--bsd-hsl) / 0.1);
	--bsd-400:
		0 0.0625rem 0.0625rem hsl(var(--bsd-hsl) / 0.15),
		0 0.125rem 0.125rem hsl(var(--bsd-hsl) / 0.15),
		0 0.25rem 0.25rem hsl(var(--bsd-hsl) / 0.15),
		0 0.5rem 0.5rem hsl(var(--bsd-hsl) / 0.15),
		0 1rem 1rem hsl(var(--bsd-hsl) / 0.15),
		0 2rem 2rem hsl(var(--bsd-hsl) / 0.15),
		0 4rem 4rem hsl(var(--bsd-hsl) / 0.15);
}

[data-theme=evry-default] {
	--lo-container-side-padding: 1rem;
	--lo-page-aside-content--aside-width: 18.75rem;
	/* form elements */
	--form-element-small-height: 1.75rem;
	--form-element-small-font-size: 0.75rem;
	--form-element-medium-height: 2.5rem;
	--form-element-medium-font-size: 0.875rem;
	--form-element-large-height: 3rem;
	--form-element-large-font-size: 1rem;
	--form-element-border-radius: 0.375rem;
	--form-element-label-font-size: 0.875rem;
	--form-element-label-font-family: var(--font-family-secondary);
	--form-element-label-font-weight: 600;
	--select-font-weight: 400;
	/* message */
	--message-font-size: 0.875rem;
	--message-icon-font-size: 1.375rem;
	/* button */
	--button-border-radius: 0.5rem;
	--button-font-weight: 500;
	--button-font-family: var(--font-family-primary);
	--button-small-height: 1.75rem;
	--button-small-font-size: 0.8125rem;
	--button-medium-height: 2.5rem;
	--button-medium-font-size: 0.9375rem;
	--button-large-height: 3rem;
	--button-large-font-size: 1.125rem;
	/* select-button */
	--select-button-font-weight: 400;
	--select-button-font-family: var(--font-family-primary);
	--select-button-small-height: 1.75rem;
	--select-button-small-font-size: 0.75rem;
	--select-button-medium-height: 2rem;
	--select-button-medium-font-size: 0.875rem;
	--select-button-large-height: 2.625rem;
	--select-button-large-font-size: 1.125rem;
	/* tab */
	--tab-font-weight: 500;
	--tab-font-size: 0.9375rem;
	--tab-font-family: var(--font-family-secondary);
	--tab-height: 2.5rem;
	--tab-padding: 0 1.25rem;
	--tab-border-radius: 0.5rem;
	--tab-border-width: 0.0625rem;
	--tab-content-border-radius: 0.5rem;
	--tab-box-shadow: var(--ui-box-shadow-1);
	/* table */
}
