.#{$prefix}message {
	font-size: var(--message-font-size);
	font-weight: 400;
	position: relative;
	display: flex;
	padding: toRem(16) toRem(24);
	color: hsl(var(--hsl-primary));
	border: toRem(1) solid hsl(var(--hsl-primary) / 0.2);
	border-radius: toRem(8);
	background: hsl(var(--hsl-primary) / 0.05);

	> p {
		flex: 1;
	}

	.icon {
		font-size: var(--message-icon-font-size);
		display: flex;
		align-items: center;
	}

	.close {
		position: absolute;
		top: toRem(8);
		right: toRem(8);
		display: flex;
		align-items: center;
		justify-content: center;
		width: toRem(24);
		height: toRem(24);
		cursor: pointer;
		transition: opacity 0.16s ease-in-out;
		opacity: 0.7;
		border-radius: 999px;

		&:hover {
			opacity: 1;
		}

		&:before, &:after {
			position: absolute;
			left: toRem(11);
			width: toRem(2);
			height: toRem(16);
			content: ' ';
			background-color: currentColor;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	&.is-show-close {
		padding-right: toRem(44);
	}

	&.is-show-icon {
		> p {
			padding-left: toRem(16);
		}
	}

	&.is-variant-positive {
		color: hsl(var(--hsl-positive));
		border: toRem(1) solid hsl(var(--hsl-positive) / 0.3);
		background: hsl(var(--hsl-positive) / 0.05);
	}

	&.is-variant-negative {
		color: hsl(var(--hsl-negative));
		border: toRem(1) solid hsl(var(--hsl-negative) / 0.3);
		background: hsl(var(--hsl-negative) / 0.05);
	}

	&.is-variant-warning {
		color: hsl(var(--hsl-warning));
		border: toRem(1) solid hsl(var(--hsl-warning) / 0.3);
		background: hsl(var(--hsl-warning) / 0.05);
	}

	&.is-variant-info {
		color: hsl(var(--hsl-info));
		border: toRem(1) solid hsl(var(--hsl-info) / 0.3);
		background: hsl(var(--hsl-info) / 0.05);
	}
}
