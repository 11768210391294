.#{$prefix}badge {
	font-family: var(--font-family-secondary);
	font-size: var(--fs-2);
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: toEm(28);
	padding: toEm(12);
	color: hsl(var(--hsl-primary));
	border-radius: 999px;
	background: hsl(var(--hsl-primary) / 0.05);

	&.is-variant-accent {
		color: hsl(var(--hsl-accent));
		background: hsl(var(--hsl-accent) / 0.05);
	}

	&.is-variant-positive {
		color: hsl(var(--hsl-positive));
		background: hsl(var(--hsl-positive) / 0.15);
	}

	&.is-variant-negative {
		color: hsl(var(--hsl-negative));
		background: hsl(var(--hsl-negative) / 0.05);
	}

	&.is-variant-warning {
		color: hsl(var(--hsl-warning));
		background: hsl(var(--hsl-warning) / 0.05);
	}

	&.is-variant-info {
		color: hsl(var(--hsl-info));
		background: hsl(var(--hsl-info) / 0.05);
	}
}
