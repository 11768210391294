// color
$placeholder-light: #a0a3bd;

$color-gray: #c4c4c4;
$color-gray5: #e0e0e0;

$color-card: #282c5b;
$color-text: #bdbdbd;

$color-primary: #71cff5;
$color-secondary: #005081;
$color-background: #232156;
$color-blue: #4571b9;
$color-light-blue: #8bf3ff;
$color-red: #be3a66;
$color-green: #27ae60;
$color-green-yellow: #8cc241;
$color-error-warning: #eb5757;
$color-header-bg: #141435;

// button
$color-btn: #014e7f;
$color-btn-text: #100f24;
$color-btn-gradient: linear-gradient(270deg, #9fcb3a 0%, #72cef3 103.92%);
$color-btn-disable: #2d313e;
$color-btn-text-disable: rgba(255, 255, 255, 0.5);
$color-btn-border: #71d0f5;
$color-border-from: #72cff4;
$color-border-to: #8cc241;

//input
$color-input-initial: #141435;

$mobileWidth: 768px;

$font-sizes: (
  2sm: 12px,
  sm: 14px,
  md: 16px,
  lg: 22px,
  xl: 26px,
  2xl: 28px,
  3xl: 40px,
);
