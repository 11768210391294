@import '../../../styles/variable';
@import '../../../styles/ultis.scss';

.modal-connect-network {
  max-width: 500px;
  .ant-modal-close {
    margin-top: to-rem(20px);
    margin-right: to-rem(20px);
  }
  .ant-modal-content {
    .ant-modal-header {
      padding-top: 48px;
    }
    .ant-modal-title {
      padding: 0 6px;
    }
    .ant-modal-body {
      padding: 0px 35px;
      span {
        font: normal 500 16px/22px var(--ffml-primary);
        color: $color-text;
      }
      ol {
        counter-reset: item;
        list-style-type: none;
        li {
          display: block;
          & a {
            color: hsl(var(--hsl-primary));
          }
        }
        li:before {
          content: counter(item) '  ';
          counter-increment: item;
          padding-right: 3px;
          @include font-size(md);
        }
        .ant-checkbox-wrapper {
          align-items: center;
          margin: 10px 0px;
          .ant-checkbox {
            padding: 0;
            font-size: 0;
            line-height: 0;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $color-green-yellow;
            border-color: $color-green-yellow;
          }
        }
        .list {
          display: flex;
          justify-content: space-between;
          .list-item {
            cursor: pointer;
            &__icon {
              position: relative;
              text-align: center;
              transition: all 0.3s ease-in-out;
              .icon__image {
                border: 2px solid $color-card;
                width: 78px;
                height: 78px;
                border-radius: 50%;
                transition: all 0.2s ease-in-out;
                user-select: none;
                opacity: 1;
                &:hover {
                  border-color: hsl(var(--hsl-primary) / 0.5)
                }
                &.icon__image--error {
                  background: hsl(var(--hsl-primary));
                }
                @include media-query-to-mobile(md) {
                  width: 54px;
                  height: 54px;
                }
              }
              .icon__checked {
                position: absolute;
                left: 50%;
                transform: translateX(50%);
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                .checked__image {
                  width: to-rem(26px);
                  height: to-rem(26px);
                  &.checked__image--error {
                    display: block;
                    background: $color-green-yellow;
                    border: 2px solid hsl(var(--hsl-primary));
                  }
                  @include media-query-to-mobile(md) {
                    width: to-rem(18px);
                    height: to-rem(18px);
                  }
                }
              }
            }
            &__text {
              @include font-size(sm);
              display: block;
              text-align: center;
              @include media-query-to-mobile(md) {
                @include font-size(2sm);
              }
            }

            &.active {
              .list-item__icon {
                .icon__image {
                  border-color: hsl(var(--hsl-primary));
                  &.icon__image--error {
                    background: $color-green-yellow;
                  }
                }
                .icon__checked {
                  opacity: 1;
                }
              }
            }
            &.disable {
              cursor: not-allowed;
              opacity: 0.3;
              .list-item__icon {
                filter: grayscale(1);
                .icon__image {
                  &:hover {
                    border-color: $color-card;
                  }
                }
              }
            }

            @include media-query-to-mobile(md) {
              &.wallet {
                .list-item__icon {
                  .icon__image {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
