@import '../../styles/ultis';
@import '../../styles/_variable';

$base-width: 425px;
$base-height: 700px;

* {
  color: hsl(var(--hsl-content));
}

.new-home {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  .layout {
    width: 100%;
    margin: 0 auto;
    @include media-query(xxl) {
      width: to-rem(1440px);
      margin: 0 auto;
    }
    .daily-quota {
      padding-top: to-rem(35px);
      font: normal 500 16px/18.75px var(--ffml-primary);
      color: hsl(var(--hsl-content) / 0.7);
      @include media-query-to-mobile(md) {
        padding-top: to-rem(15px);
        white-space: pre-line;
      }
    }
  }

  /* Swap Area */
  .main-section {
    display: flex;
    margin: to-rem(40px) auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column-reverse;

    .home-descrip {
      text-align: center;
      padding: 0 16px;
      display: flex;
      flex-wrap: wrap;
      margin: to-rem(20px) 0;
      align-content: center;
      justify-content: center;
      @include font-size(sm);
      color: hsl(var(--hsl-content) / 0.5);
    }

    .left {
      display: flex;
      width: to-rem(560px);
      margin-top: 30px;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      height: auto;

      @include media-query-to-mobile(sm) {
        width: 100%;
        padding: 0 to-rem(16px);
      }

      &-main {
        display: flex;
        width: 100%;
        height: auto;
        padding: 10px 0;
        background-color: hsl(var(--hsl-base-300) / 0.7);
        border: 1px solid hsl(var(--hsl-primary) / 0);
        border-radius: 5px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        &-name {
          @include font-size(lg);
          font-weight: bold;
        }

        &-description {
          @include font-size(sm);
          font-weight: normal;
          color: $color-text;
        }

        &-link {
          margin: 10px;
          @include font-size(md);
          font-weight: bold;

          a {
            color: hsl(var(--hsl-primary));
            line-height: 16px;
            font-weight: 500;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .swap-content {
      display: flex;
      width: to-rem(560px);
      justify-content: center;
      align-items: flex-start;

      @include media-query-to-mobile(sm) {
        width: 100%;
      }

      &__layout {
        width: 100%;

        @include media-query-to-mobile(sm) {
          width: calc(100% - (16px * 2));
        }

        /* Form swap */
        .form-swap {
          padding: 0 to-rem(16px);

          @include media-query-to-mobile(md) {
            padding: 0 to-rem(10px);
          }
          .from-to-network-mobile-container {
            @include media-query(md) {
              display: none !important;
            }
          }

          .select-token-group {
            padding-top: to-rem(20px);
            width: 100%;

            &__select-field {
              padding: to-rem(13px) 0;
              max-height: to-rem(55px);
              background-color: hsl(var(--hsl-primary) / 0.15);
              border-radius: to-rem(5px);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;

              &.wait {
                cursor: wait;
              }

              .image--error {
                background: $color-text;
              }

              .field {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;
                flex: 1;

                .spinning {
                  animation: loading 2s linear infinite;
                  width: to-rem(22px);
                  height: to-rem(22px);
                  margin-left: to-rem(10px);
                  margin-top: to-rem(5px);
                  margin-bottom: to-rem(5px);
                }

                .des-text {
                  @include font-size(md);
                }
              }

              .network-name {
                // flex: 1;
                @include font-size(md);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include media-query-to-mobile(md) {
                  @include font-size(sm);
                }
              }

              // .drop-down-icon {
              // align-items: flex-end;
              // width: to-rem(25px);
              // height: to-rem(25px);
              // }
            }

            &__text {
              color: hsl(var(--hsl-content));
              font-size: var(--fs-4);
              font-weight: 600;
            }

            &__add-to-wallet {
              width: 100%;
              height: auto;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;

              span {
                display: flex;
                align-items: center;
                @include font-size(md);
                .add-token-icon {
                  width: to-rem(20px);
                  height: to-rem(20px);
                  margin: to-rem(15px) to-rem(10px) to-rem(15px) 0;
                }
              }
            }

            .amount-input-container {
              width: 100%;
              height: auto;
              position: relative;
            }

            &__amount {
              width: 100%;
              padding: to-rem(15.5px) to-rem(16px);
              height: to-rem(55px);
              border-radius: 5px;
              min-height: to-rem(50px);
              background-color: hsl(var(--hsl-primary) / 0.15);
              color: hsl(var(--hsl-content));
              font-family: var(--ffml-primary);
              font-weight: 600;
              // position: relative;
              padding-right: to-rem(100px);

              &__max-btn {
                display: flex;
                position: absolute;
                top: to-rem(12px);
                right: to-rem(15px);
                padding: to-rem(3px) to-rem(18px);
                max-width: to-rem(67px);
                align-items: center;
                justify-content: center;
                z-index: 99;
                background-color: transparent;
                color: white;
                border: 1px solid $color-primary;
                border-radius: to-rem(5px);
                user-select: none;

                &:hover {
                  opacity: 0.8;
                  cursor: pointer;
                }
                &.disable {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }
            }

            &__available {
              margin-top: to-rem(15px);
            }

            &__alert {
              width: 100%;
              padding: to-rem(16.5px) 0;
              margin: to-rem(20px) 0;
              border-radius: 5px;
              background-color: hsl(var(--hsl-base-200) / 0.5);
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;

              .alerticon {
                padding: to-rem(5px) to-rem(10px);
              }

              .alerttext {
                width: 85%;
                @include font-size(md);
                color: hsl(var(--hsl-content) / 0.7);
              }
            }

            &__reminder {
              display: flex;
              width: 100%;
              padding: to-rem(14px) 0;
              margin-bottom: to-rem(30px);
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              border-radius: 5px;
              //border: 1px solid hsl(var(--hsl-primary));
              background-color: hsl(var(--hsl-base-200) / 0.5);

              .reminder-icon {
                padding: to-rem(5px) to-rem(10px);
              }

              .reminder-text {
                padding: to-rem(5px) to-rem(15px);
                font-weight: 400;
                @include font-size(md);
                color: hsl(var(--hsl-content) / 0.7)
              }
            }

            .field-icon {
              margin-left: 15px;
              &.image--error {
                background: $color-text;
              }
            }
          }

          .form-container {
            width: 100%;
            height: auto;
            position: relative;

            &__child {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 24px;

              .from-border {
                border: to-rem(1px) solid $color-border-from;
              }

              .to-border {
                border: to-rem(1px) solid $color-border-to;
              }

              .from-to-network-container {
                height: to-rem(170.37px);
                width: to-rem(230.2px);
                padding: to-rem(15px);
                border-radius: to-rem(10px);
                background-color: hsl(var(--hsl-white) / 0.05);

                @include media-query(md) {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  cursor: pointer;
                }
                &.wait {
                  cursor: wait;
                }
                .networkname-container {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: space-between;
                  margin: to-rem(10px) 0;

                  .leftname {
                    @include font-size(md);
                    font-weight: 600;
                    width: 80%;
                    margin: 0;
                  }
                }
              }
              @include media-query-to-mobile(md) {
                display: none !important;
              }
            }
            @mixin polygon-clip() {
              display: flex;
              align-items: center;
              justify-content: center;
              clip-path: path(
                'M46.9,17.9a8.2,8.2,0,0,1,0,8.3L39,39.8a8.1,8.1,0,0,1-7.1,4.1H16.2A8.2,8.2,0,0,1,9,39.8L1.1,26.2a8.7,8.7,0,0,1,0-8.3L9,4.3A8.4,8.4,0,0,1,16.2.2H31.9A8.4,8.4,0,0,1,39,4.3Z'
              );
            }
            .middle {
              position: absolute;
              top: 50%;
              right: 50%;
              width: to-rem(48px);
              height: to-rem(45px);
              transform: translate(50%, -50%) translateZ(0) scale(1.0);
              transition: transform 0.3s;
              @include polygon-clip;
              @include colorPalette;
              @include media-query(md) {
                &:hover {
                  animation: backgroundColorPalette 2s ease-in-out infinite
                    alternate;
                  cursor: pointer;
                  transform: translate(50%, -50%) translateZ(0) scale(1.14);
                }
              }

              @include media-query-to-mobile(md) {
                transform: translate(50%, 0) translateZ(0) scale(1.1)
                  rotate(90deg);
                right: 50%;
                margin-top: to-rem(5px);
                width: to-rem(48px);
                height: to-rem(45px);
              }
              img {
                width: to-rem(20px);
              }
            }
          }

          .connect-metamask-message {
            width: 100%;
            font-weight: 500;
            @include font-size(md);
            margin: to-rem(20px) 0;

            .add-network-link {
              color: $color-primary;
            }
          }

          .eos {
            width: 100%;
            margin-top: to-rem(30px);
            display: flex;
            flex-direction: row;
            justify-self: start;
            align-items: center;

            &__text {
              font-weight: 500;
              @include font-size(md);
            }

            .eos-icon {
              margin: 0 to-rem(5px);
            }
          }

          .btn-group {
            width: 100%;
            margin: to-rem(30px) 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__choosen {
              width: to-rem(180px);
              height: auto;

              border-radius: to-rem(5px);
              background: $color-secondary;
              border: none;
              cursor: pointer;
              border: 2px solid $color-secondary;

              display: flex;
              justify-content: center;
              align-items: center;

              &.active {
                border: 2px solid $color-primary;
                background-color: $color-blue;
              }

              &__label {
                padding: to-rem(13px) 0;
              }
            }
          }
        }

        .line {
          margin-top: to-rem(45px);
          border-bottom: 1px solid #ebeced;
        }

        .check-section {
          display: flex;
          padding: 0 to-rem(10px);
          align-items: center;
        }

        .swap-button {
          width: 100%;
          margin: to-rem(20px) 0;
          text-align: center;
          position: static;
          background-color: transparent;

          @include media-query-to-mobile(md) {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            margin: 0;
            padding: to-rem(16px) to-rem(20px);
            background-color: hsl(var(--hsl-base-300));
            z-index: 999;
            .ant-form-item {
              margin: 0;
            }
          }

          &__btn {
            $height: 55px;
            $padding-vertical: 10px;
            $padding-horizontal: 0px;
            $content-height: $height - $padding-vertical * 2 - 10px;
            width: 100%;
            height: to-rem($height);
            padding: to-rem($padding-vertical) to-rem($padding-horizontal);
            border-radius: to-rem(999px);
            border: none;
            color: hsl(var(--hsl-primary-content)) !important;
            font-family: var(--ffml-primary);
            @include font-size(md);
            @include media-query-to-mobile(md) {
              @include font-size(sm);
              height: to-rem(44px);
            }
            @include colorPalette;
            &:hover {
              animation: backgroundColorPalette 3.5s ease-in-out infinite
                alternate;
            }
            span {
              color: #100f24;
            }
            &[disabled],
            &[disabled]:hover,
            &[disabled]:focus,
            &[disabled]:active {
              background-image: none;
              background-color: $color-btn-disable;
              span {
                color: $color-btn-text-disable;
              }
            }

            &.loading {
              .loading__icon {
                display: inline-block;
                animation: loading 2s linear infinite;
              }
              img {
                width: to-rem($content-height);
                height: to-rem($content-height);
                filter: grayscale(1);
              }
            }
          }
        }

        /* End form swap */
      }
    }
    @include media-query-to-mobile(md) {
      width: 100%;
      margin-top: to-rem(20px);
      margin-bottom: to-rem(95px);
      .home-descrip {
        margin-bottom: 0;
      }
    }
  }
}

.note {
  font-size: to-rem(11.5px);
  margin-bottom: 0;
}


.modal-normal {
  max-width: to-rem(394px);
  height: 374px;

  .ant-modal-content {
    .ant-modal-close {
      margin-top: to-rem(19px);
      margin-right: to-rem(30px);
      &-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }
    .ant-modal-header {
      padding-top: 46px;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-body {
      padding: 0px 30px 30px;
      margin-top: -15px;
      & > img {
        display: block;
        width: to-rem(80px);
        margin: 0 auto;
        padding-bottom: 24px;
      }
      & > h6 {
        display: inline-block;
        font: normal 400 16px/20px var(--ffml-primary);
        color: $color-text;
        text-align: center;
        margin: 0;
      }
    }

    .ant-modal-footer {
      padding: 6px 30px 30px 30px;
      .modal-normal__close-btn {
        width: 100%;
        height: 44px;
        background: $color-btn-gradient;
        border-radius: 5px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
        font: normal 600 14px/20px var(--ffml-primary);
        outline: none;
        border: none;
        opacity: 0.9;
        &--content {
          color: #100f24;
        }
        &:hover {
          opacity: 1;
        }
      }

      .modal-normal-dismiss__close-btn {
        width: 100%;
        height: 44px;
        background-color: $color-red;
        border-radius: 5px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
        font: normal 600 14px/20px var(--ffml-primary);
        outline: none;
        border: none;
        opacity: 0.9;
        color: #100f24;
        &--content {
          color: #100f24;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@include media-query-to-mobile(md) {
  .modal-normal {
    .ant-modal-footer,
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 38px 24px !important;
      & > img {
        // padding-top: to-rem(49px);
      }
      & > h6 {
        // padding-bottom: to-rem(30px);
      }
    }
  }
}
