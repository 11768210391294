$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 932px,
  xl: 1080px,
  xxl: 1100px,
);

$grid-breakpoints: (
  xs: 375px,
  sm: 540px,
  md: 720px,
  lg: 932px,
  xl: 1080px,
  xxl: 1100px,
);

@mixin media-query($key, $breakpoint: $grid-breakpoints) {
  @media (min-width: map-get($breakpoint, $key)) {
    @content;
  }
}

@mixin media-query-to-mobile($key, $breakpoint: $grid-breakpoints) {
  @media (max-width: map-get($breakpoint, $key)) {
    @content;
  }
}

@mixin truncate-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin make-container(
  $max-width: $container-max-widths,
  $breakpoint: $grid-breakpoints
) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 to-rem(15px);
  @each $breakpoint, $container-max-width in $max-width {
    @include media-query($breakpoint) {
      max-width: $container-max-width;
    }
  }
}

$base-size: 16px;

@function to-rem($size, $context: $base-size) {
  @return $size / $base-size * 1rem;
}

@function to-public-path($url) {
  @return '%PUBLIC_URL%/#{$url}';
}

@mixin colorPalette($percent-l: 0, $percent-r: 103.92) {
  background: linear-gradient(290deg, hsl(var(--hsl-accent)), hsl(var(--hsl-primary)), hsl(var(--hsl-accent)));
  background-position: 105% 0%;
  background-size: 200% 200%;
}

@keyframes backgroundColorPalette {
  0% {
    background-position: 105% 0%;
  }
  50% {
    background-position: 10% 0%;
  }
  100% {
    background-position: 105% 0%;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin font-size($key, $breakpoints: $font-sizes) {
  font-size: map-get($breakpoints, $key);
}

.u-input-style {
  background-color: hsl(var(--hsl-primary) / 0.15) !important;
  border: 1px solid transparent !important;
  transition: all 0.16s ease-in-out !important;
  outline: none;
  box-shadow: none !important;

  &.is-v2 {
    background-color: hsl(var(--hsl-content) / 0.05) !important;
  }

  &:hover, &:focus {
    box-shadow: 0 0 0 2px hsl(var(--hsl-primary) / 0.2) !important;
    border-color: hsl(var(--hsl-primary)) !important;
  }
}
