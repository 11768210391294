@import '../../../styles/_variable';
@import '../../../styles/ultis.scss';

.header {
  display: flex;
  position: sticky;
  top: 0;
  padding: 24px 0 12px 0;
  min-height: 32px;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: hsl(var(--hsl-black) / 0.9);
  //filter: blur(8px);
  //-webkit-filter: blur(8px);

  &-logo {
    width: auto;
    // max-height: to-rem(47px);
    display: flex;
    align-items: center;
    margin-left: to-rem(60px);
    @include media-query-to-mobile(xl) {
      margin-left: to-rem(25px);
    }

    @include media-query-to-mobile(md) {
      max-height: to-rem(26px);
      margin-left: to-rem(25px);
    }
    @include media-query-to-mobile(sm) {
      margin-left: to-rem(19px);
      margin-right: to-rem(19px);
    }

    &__logo {

    }
    .image {
      height: 30px;

      @include media-query-to-mobile(sm) {
        height: 30px;
      }

      &--error {
        vertical-align: middle;
        width: 38px;
        height: 38px;
        background: $color-text;
        @include media-query-to-mobile(md) {
          width: 26px;
          height: 26px;
        }
      }
    }
    &__menu-button {
      background: transparent;
      border: none;
      img {
        width: 24px;
      }
    }
    &--active {
      .header-logo__logo {
        width: 36px;
      }
    }
  }
  .btn-group {
    &__item {
      .ant-dropdown-trigger {
        height: auto;
      }
      @include media-query-to-mobile(md) {
        &.history-link {
          display: none;
        }
        &.wallet-connected {
          display: none;
        }
      }
    }
  }
}

.overlay__drop-down-menu {
  $default-opacity: 0.2;
  $hover-opacity: 0.2;
  $selected-opacity: 0.6;
  background: $color-header-bg;
  border-radius: 5px;
  @include media-query-to-mobile(md) {
    width: 100%;
  }
  .overlay__drop-down-menu--wrapper {
    background: hsl(var(--hsl-base-200));
    color: hsl(var(--hsl-content));
    border-radius: 5px;
    border: 0;

    &.select-network {
      padding: 0;
      border-radius: 5px;
      .ant-menu-item {
        height: auto;
        margin: 0;
        padding: 0;
        border-radius: 5px;
        &-active {
          background: hsl(var(--hsl-primary) / 0.2);
          color: hsl(var(--hsl-content));
        }
        &-selected {
          background: hsl(var(--hsl-primary) / 0.5);
          color: hsl(var(--hsl-content));
        }
        .item-select {
          padding: 5px 15px;
          line-height: 36px;
          height: auto;
        }
        @include media-query-to-mobile(md) {
          line-height: 0;
          .item-select {
            padding: 5px 10px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.item-select {
  display: inline-block;
  // align-items: center;

  &__icon {
    display: inline-block;
    margin-right: 15px;
    line-height: 20px;
    @include media-query-to-mobile(md) {
      margin-right: 6px;
      line-height: 16px;
    }
    .image {
      width: to-rem(30px);
      @include media-query-to-mobile(md) {
        width: to-rem(16px);
        height: to-rem(16px);
      }
      &--error {
        vertical-align: middle;
        height: to-rem(30px);
        background: $color-text;
      }
    }
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
    min-width: 100px;
    text-align: left;
    @include font-size(md);
    @include media-query-to-mobile(md) {
      min-width: 70px;
      @include font-size(sm);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.overlay__disconnect-button {
  $inactive-color: $color-blue;
  $active-color: #4571aa;
  padding: 7px 30px;
  border-radius: 5px;
  color: white;
  background: $inactive-color;
  border: none;
  @include font-size(sm);
  transition: all 0.3s;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    background: $active-color;
  }
  @include media-query-to-mobile(md) {
    width: 95%;
    margin-left: 5%;
    text-align: center;
  }
}

.btn-group {
  padding: 0;
  // margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // align-items: center;
  margin-right: to-rem(60px);
  @include media-query-to-mobile(md) {
    margin-right: to-rem(25px);
  }
  @include media-query-to-mobile(sm) {
    margin-right: to-rem(19px);
  }

  &__item {
    display: flex;
    margin-right: to-rem(30px);
    @include media-query-to-mobile(md) {
      margin-right: 0;
    }
    &.history-link {
      align-items: center;
      font-weight: 600;
      border: none;
      a {
        display: flex;
        flex: 1;
        align-items: center;
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
      @include media-query-to-mobile(md) {
        padding: 5px 10px;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    &.item-interact {
      flex-direction: column;
      background: rgba(#72cff4, 0.2);
      border-radius: to-rem(5px);
      .button {
        @include media-query-to-mobile(md) {
          line-height: 16px;
        }
      }
    }
    &.network-connected {
      .drop-down {
        display: flex;
        height: 100%;
        align-items: center;
        color: hsl(var(--hsl-content));

        @include media-query-to-mobile(md) {
          padding: 5px 10px;
          .button__arrowDown {
            padding-left: 0;
            .drop-down-icon {
              width: 18px;
            }
          }
        }
      }
    }
    &.wallet-connected {
      // justify-content: flex-start;
      padding: 0 8px;

      cursor: pointer;
      .wallet {
        display: flex;
        padding-top: 2px;
        padding-bottom: 1px;
        height: auto;
        // padding: to-rem(5px);
        // flex-direction: row;
        align-items: center;
        &__icon {
          display: flex;
          .image {
            max-width: 18px;
            &--error {
              width: 18px;
              height: 18px;
              background: $color-text;
            }
            @include media-query-to-mobile(md) {
              max-width: 15px;
              &--error {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        &__text {
          display: inline-block;
          margin-left: 5px;
          color: $color-text;
        }
      }

      .wallet > div {
        padding: to-rem(5px) to-rem(10px);
      }

      .network-name {
        margin: 0 0 2px 0;
        font-weight: 600;
      }
    }
  }
}

.connect-wallet-btn {
  border-radius: 999px;

  &__icon {
    img {
      width: to-rem(16px);
    }
  }

  //&__text {
  //  margin-left: 8px;
  //  padding-top: 3px;
  //  font-family: var(--ffml-primary);
  //  font-weight: 800;
  //  text-align: center;
  //  white-space: nowrap;
  //}
}

@keyframes logo-swipe-to-left {
  0% {
    transform: translateX(350%);
  }
  100% {
    transform: translateX(0%);
  }
}

.drawer-mobile {
  .ant-drawer-mask {
    background: transparent;
  }
  &.ant-drawer-left.ant-drawer-open {
    .ant-drawer-content-wrapper {
      box-shadow: 20px 0px 30px rgba(0, 0, 0, 0.25);
      @include media-query-to-mobile(md) {
        max-width: 45%;
        min-width: to-rem(170px);
      }
    }
  }
  .ant-drawer-content {
    background: $color-background;
  }
  .ant-drawer-header {
    padding: to-rem(18px);
    border: none;
    background: $color-background;
    .logo {
      display: flex;
      &--active {
        .header-logo__logo {
          animation: logo-swipe-to-left 0.3s ease-in-out forwards;
          .image {
            width: to-rem(26px);
            height: to-rem(26px);
            &--error {
              background: $color-text;
            }
          }
        }
      }
    }
  }
  .ant-drawer-body {
    padding: to-rem(16px);
  }
  .connect-wallet-btn {
    width: 100%;
    justify-content: center;
    &__icon {
      img {
        width: to-rem(15px);
      }
    }
    @include media-query-to-mobile(sm) {
      &__text {
        font-size: 10px;
      }
    }
  }
  .btn-group {
    flex-direction: column;
    margin-right: 0;

    .wallet-connected {
      width: 100%;
      height: to-rem(45px);
      line-height: to-rem(45px);
      overflow: hidden;
    }
  }
}

.network-match {
  text-align: center;
  overflow: hidden;
  p {
    margin: 0;
    padding: 14px 0;
    background: rgba(250, 58, 102, 0.4);
    @include font-size(md);
    font-family: var(--ffml-primary);
    animation: flip-down 0.4s linear forwards;
    span {
      color: $color-text;
    }
    .add-network {
      color: $color-primary;
      text-decoration: underline;
    }
    .change-network {
      color: $color-primary;
    }
  }
}
@keyframes flip-down {
  0% {
    transform: translateY(-3000px);
  }
  5% {
    transform: translateY(-200px);
  }
  33% {
    transform: translateY(-140px);
  }
  38% {
    transform: translateY(-45px);
  }
  66% {
    transform: translateY(-45px);
  }
  71% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
